import React, { useState, useEffect, useRef } from 'react';
import Layoutas from '../../components/Layout';
import Mapbox from '../../mapbox'
import Calculate from '../../util/calculate';

import Sidetab from '../../components/Sidetab'
import '../index.css';

import TumbonAddr from '../../util/TumbonAddr'
import Overview from "./map_ecd_contents/Overview";
import api from '../../util/api';
import getProvinceName from '../../util/getProvinceName'

const FullMap = (props) => {

  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = React.useState(0);
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const [initData, setInitData] = useState(null)
  const is_Click = useRef(false);
  const [filter, setFilter] = React.useState({
    minView: "country",
    view: 'country',
    year: "2563",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    checkbox_side: []
  });
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res = await api.getInitData('ecd')
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        setInitData(res.data.filter(item => item.province_name === getProvinceName(pid)))
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        setInitData(res.data)
      }
    }
  }, [])

  // useEffect(async () => {
  //   let res = await api.getInitData('ecd')
  //   setInitData(res.data)
  // }, [])

  useEffect(() => {
    if (initData === null) return null
    let data = initData
    let response = onProcessData({
      province: filter['province_name'],
      city: filter['city_name'],
      tumbon: filter['tumbon_name'],
      layer: filter.view
    }, convertMapContent(filter.view, data.map(x => ({ ...x, total: 1 }))))
    if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
      if (filter.city_name !== null) {
      } else {
      }
    }

    let border_filter = response.data
    if (filter.checkbox_side.length > 0) {
      if (filter.view === 'country') {
        border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.province))
        console.log(filter.checkbox_side, border_filter)
      } else if (filter.view === 'province') {
        border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.city))
      } else if (filter.view === 'city') {
        border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.tumbon))
      }
    }
    setDataMap([
      { id: 'border', layer: filter.view, data: border_filter },
    ])
    let data_legend = [
      { name: 'เด็กปฐมวัยกลุ่มเป้าหมาย', value: response.data.reduce((acc, cur) => acc + cur.total_ecd, 0), unit: 'คน' },
      { name: 'เด็กปฐมวัยยากจน', value: response.data.reduce((acc, cur) => acc + cur.sum_ecd, 0), unit: 'คน' },
    ]
    setLegendData({
      ...legend_data,
      title: filter.province_name,
      subtitle: filter.city_name,
      data: data_legend,
    })
  }, [filter, initData])


  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => {
      return {
        province: item,
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });

    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => {
      return {
        province: item.split('_')[0],
        city: item.split('_')[1],
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => {
      return {
        province: item.split('_')[0], city: item.split('_')[1],
        tumbon: item.split('_')[2],
        total_ecd: 0,
        target_ecd: 0,
        sum_ecd: 0,
      }
    });

    data.forEach((item) => {
      let total = (item.province20)
      let count = (item.province20)
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (province[index]) {
          province[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          province[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          province[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = province
        }
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (city[index]) {
          city[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          city[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          city[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = city
        }
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (tumbon[index]) {
          tumbon[index]['total_ecd'] += item[`year${filter.year}_total_ecd`]
          tumbon[index]['target_ecd'] += item[`year${filter.year}_target_ecd`]
          tumbon[index]['sum_ecd'] += item[`year${filter.year}_sum_ecd`]
          res = tumbon
        }
        res = tumbon
      }
    })
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['sum_ecd'] / element['target_ecd'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['sum_ecd'] / result.map(item => item.sum_ecd).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.sum_ecd)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.sum_ecd)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.sum_ecd)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.sum_ecd)
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'province'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      } else if (layer === 'city' && filterBorder.city) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'city'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  let tab_content = [
    {
      title: 'ภาพรวม',
      isHide: true,
      content: <Overview data={data_map.length > 0 ? data_map[0].data : []} filter={filter}
        onUpdateFilter={(filter) => {
          setFilter({ ...filter })
        }}
      />,
    },
  ]

  return (
    <Layoutas Role={['partner', 'eef', 'partner_province']} isHideFooter>
      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
          />

        </div>
        <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
          <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
            <span className='font-bold center mx-1 mb-2'>สัดส่วนกลุ่มเป้าหมายที่ได้รับการสำรวจ</span>
            <div className='flex'>
              <div className='flex flex-col'>
                <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                </div>
                <div class="font-bold text-left">
                  มาก
                </div>
              </div>
              <div className='flex flex-col'>
                <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                </div>
              </div>
              <div className='flex flex-col'>
                <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                </div>
              </div>
              <div className='flex flex-col'>
                <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                </div>
                <div class="font-bold text-right">
                  น้อย
                </div>
              </div>
              <div className='ml-4  flex flex-col'>
                <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                </div>
                <div class="font-bold text-center">
                  ไม่มีข้อมูล
                </div>
              </div>
            </div>
          </div>
        </div>

        <Sidetab
          title={`แผนที่เด็กปฐมวัยยากจนใน ศพด. ที่ได้รับความช่วยเหลือ ในพื้นที่ 20 จังหวัด`}
          tab={tab_content}
          titleRef='*เด็กปฐมวัยยากจนใน ศพด. ข้อมูลจำนวนเด็กปฐมวัยที่ได้รับความช่วยเหลือในพื้นที่ 20 จังหวัดเสมอภาคและกรุงเทพมหานคร ในปีการศึกษา 2563'
          subjectTitleRef='*ประมวลผล ณ เดือนเมษายน 2565'
          open={open}
          tabvalue={tabvalue}

          setOpen={setOpen}
        />

      </div>
    </Layoutas >
  )
}

export default FullMap
