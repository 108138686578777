import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { Box } from '@mui/material';
import FilterKRT from './FilterKRT'
import CheckbooxButton from '../../../components/CheckbooxButton'

let PROVINCE_Y2019 = [
    "กาญจนบุรี",
    "ขอนแก่น",
    "นครนายก",
    "นครราชสีมา",
    "น่าน",
    "พิษณุโลก",
    "ภูเก็ต",
    // "มหาสารคาม",
    "ยะลา",
    "ระยอง",
    "ลำปาง",
    "สงขลา",
    "สุราษฎร์ธานี",
    "สุรินทร์",
    "สุโขทัย",
    "อำนาจเจริญ",
    "อุบลราชธานี",
    "เชียงใหม่",
    // "แพร่",
    "แม่ฮ่องสอน",
    // "กรุงเทพมหานคร"
]

let PROVINCE_Y2020 = [
    "กาญจนบุรี",
    "ขอนแก่น",
    "นครนายก",
    "นครราชสีมา",
    "น่าน",
    "พิษณุโลก",
    "ภูเก็ต",
    "มหาสารคาม",
    "ยะลา",
    "ระยอง",
    "ลำปาง",
    // "สงขลา",
    "สุราษฎร์ธานี",
    "สุรินทร์",
    "สุโขทัย",
    "อำนาจเจริญ",
    "อุบลราชธานี",
    "เชียงใหม่",
    "แพร่",
    "แม่ฮ่องสอน",
    "กรุงเทพมหานคร"
]

const Overview = (props) => {

    let total_ecd = props.data.reduce((acc, b) => acc + b.total_ecd, 0)
    let target_ecd = props.data.reduce((acc, b) => acc + b.target_ecd, 0)
    let sum_ecd = props.data.reduce((acc, b) => acc + b.sum_ecd, 0)
    let percent = (sum_ecd / target_ecd) * 100
    percent = percent ? percent : 0
    let key_map = 'province'
    let isShow = false
    let titleTable = 'จังหวัด'

    if (props.filter.view === 'province') {
        key_map = 'city'
        isShow = true
        titleTable = 'อำเภอ'
        // onBack = () => {    }
    } else if (props.filter.view === 'city') {
        key_map = 'tumbon'
        isShow = true
        titleTable = 'ตำบล'
    }

    return (
        <div className='flex flex-col'>
            {/* <div className='w-[full] flex justify-center'>
                <div className=' flex flex-col' >
                    <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
                        <div className=' flex flex-col' >
                            <h5 className="text-sm m-0 ml-[9px]" >ข้อมูลปี: </h5>
                            <div className='flex flex-row' >
                                <CheckbooxButton title='2563' checked={props.filter.year === '2563'} onClick={() => {
                                    props.onUpdateFilter({ ...props.filter, year: '2563' })
                                }} />
                                <CheckbooxButton title='2562' checked={props.filter.year === '2562'} onClick={() => {
                                    props.onUpdateFilter({ ...props.filter, year: '2562' })
                                }} />
                            </div>
                        </div>
                        < FilterKRT
                            listprovince={LIST_PROVICNE}
                            data={props.filter}
                            onChange={(res) => {
                                props.onUpdateFilter({ ...props.filter, ...res })
                            }}
                        />
                    </div>
                </div>
            </div> */}


            <div className='w-[full] flex justify-center'>
                <div className=' flex flex-col' >
                    <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
                        <div className=' flex flex-col' >
                            <h5 className="text-sm m-0 ml-[9px]" >ปีการศึกษา: </h5>
                            <div className='flex flex-row' >
                                <CheckbooxButton title='2563' checked={props.filter.year === '2563'} onClick={() => {
                                    props.onUpdateFilter({ ...props.filter, year: '2563' })
                                }} />
                                <CheckbooxButton title='2562' checked={props.filter.year === '2562'} onClick={() => {
                                    props.onUpdateFilter({ ...props.filter, year: '2562' })
                                }} />
                            </div>
                        </div>
                        < FilterKRT
                            listprovince={props.filter.year === '2563' ? PROVINCE_Y2020 : PROVINCE_Y2019}
                            data={props.filter}
                            onChange={(res) => {
                                props.onUpdateFilter({ ...props.filter, ...res })
                            }}
                        />
                    </div>
                </div>
            </div>


            <div className='pt-2 px-4 text-cennter content-center'>
                <div className='flex flex-col md:flex-row justify-between'>
                    <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[200px] p-1 my-2">
                        <h2 className='text-md md:text-md lg:text-lg text-center'>เด็กปฐมวัยกลุ่มเป้าหมาย</h2>
                        <h2 className='text-md lg:text-2xl text-center text-[#3440A6] m-0 font-bold'>{target_ecd.toLocaleString("en-US")} คน</h2>
                    </div>
                    <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[200px] p-1 my-2">
                        <h2 className='text-md md:text-md lg:text-lg text-center'>เด็กปฐมวัยยากจน</h2>
                        <h2 className='text-md lg:text-2xl text-center text-[#048967] m-0 font-bold'>{sum_ecd.toLocaleString("en-US")} คน</h2>
                    </div>
                    <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[200px] p-1 my-2">
                        <h2 className='text-md md:text-md lg:text-lg text-center'>คิดเป็น</h2>
                        <h2 className='text-md lg:text-xl text-center text-[#B91111] m-0 font-bold'>{percent.toFixed(2)} %</h2>
                    </div>
                </div>
            </div>

            <Table
                size="small"
                bordered
                scroll={{ x: 800, y: 500 }}
                style={{ width: '100%' }}
                dataSource={props.data.filter(x => {
                    let provinces = []
                    if (props.filter.year === '2563') {
                        provinces = PROVINCE_Y2020
                    } else if (props.filter.year === '2562') {
                        provinces = PROVINCE_Y2019
                    }
                    return provinces.includes(x.province)
                }).map(x => ({
                    ...x,
                    percent_table: ((x.sum_ecd / x.total_ecd) * 100).toFixed(2)
                }))

                }
                columns={[
                    {
                        title: titleTable,
                        fixed: 'left',
                        dataIndex: key_map,
                        key: key_map,
                        width: 200,
                        align: 'center',
                        render(text, record) {
                            return {
                                props: {
                                    style: { textAlign: 'left' }
                                },
                                children: <div onClick={() => {

                                    if (props.filter.view === 'country') {
                                        props.onUpdateFilter({
                                            ...props.filter,
                                            ...{
                                                view: 'province',
                                                region: "ทั้งหมด",
                                                province_name: text,
                                                city_name: null,
                                            }
                                        })
                                    } else if (props.filter.view === 'province') {
                                        props.onUpdateFilter({
                                            ...props.filter,
                                            ...{
                                                view: 'city',
                                                region: "ทั้งหมด",
                                                province_name: props.filter.province_name,
                                                city_name: text,
                                                tumbon_name: null,
                                            }
                                        })
                                    }
                                }}>
                                    {props.filter.view !== 'city' ? <a > {text}</a> : <p className='m-0' > {text}</p>}
                                </div>
                            };
                        },
                        sorter: (a, b) => {
                            if (a[key_map] < b[key_map]) {
                                return -1;
                            }
                            if (a[key_map] > b[key_map]) {
                                return 1;
                            }
                            // a must be equal to b
                            return 0;
                        },
                    },
                    {
                        title: 'เด็กปฐมวัยทั้งหมด',
                        defaultSortOrder: 'descend',
                        dataIndex: 'total_ecd',
                        key: 'total_ecd',
                        align: 'center',
                        render(text, record) {
                            return {
                                props: {
                                    style: { textAlign: "right" }
                                },
                                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            };
                        },
                        width: 240,
                        sorter: (a, b) => a.total_ecd - b.total_ecd,
                    },

                    {
                        title: 'เด็กปฐมวัยกลุ่มเป้าหมาย',
                        dataIndex: 'target_ecd',
                        key: 'target_ecd',
                        align: 'center',
                        render(text, record) {
                            return {
                                props: {
                                    style: { textAlign: "right" }
                                },
                                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            };
                        },
                        width: 240,
                        sorter: (a, b) => a.target_ecd - b.target_ecd,
                    },

                    {
                        title: 'เด็กปฐมวัยที่ได้รับทุน',
                        dataIndex: 'sum_ecd',
                        key: 'sum_ecd',
                        align: 'center',
                        render(text, record) {
                            return {
                                props: {
                                    style: { textAlign: "right" }
                                },
                                children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            };
                        },
                        width: 240,
                        sorter: (a, b) => a.sum_ecd - b.sum_ecd,
                    },

                ]}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            // let type = null
                            // if (record.key === 'ไม่ระบุ') return;
                            // if (props.filter.view === 'country') {
                            //   type = 'province'
                            //   props.updateFilter(record.key, type)
                            // } else if (props.filter.view === 'province') {
                            //   type = 'city'
                            //   props.updateFilter(record.key, type)
                            // } else if (props.filter.view === 'city') {
                            //   return;
                            // }
                        },
                    };
                }}
            >

            </Table>
        </div >
    )
}

export default Overview
